import React from "react";
import Navbar from "./Navbar.js";
import Sidebar from "./Sidebar.js";
import Content from "./Content.js";
import Footer from "./Footer.js";

function App() {
  return (
    <div  className="noteplus-layout  ">

      {/* <!-- loader Start --> */}
      <div id="loading">
            <div id="loading-center">
            </div>
      </div>
    
      {/* <!-- Modal --> */}
      <div  className="modal fade" id="create-note" tabindex="-1" role="dialog" aria-hidden="true">
        <div  className="modal-dialog modal-dialog-centered" role="document">
            <div  className="modal-content">
                <div  className="modal-body">
                    <div  className="popup text-left">
                        <h4  className="mb-4">Create New Tag</h4>
                        <div  className="content create-workform">
                            <input type="text"  className="form-control" placeholder="Enter Tag Name"/>
                            <div  className="col-lg-12 mt-4">
                                <div  className="d-flex flex-wrap align-items-ceter justify-content-center">
                                    <div  className="btn btn-outline-primary mr-4" data-dismiss="modal">Create</div>
                                    <div  className="btn btn-primary" data-dismiss="modal">Cancel</div>
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      
      {/* <!-- loader END --> */}

      {/* <!-- Wrapper Start --> */}
      <div className="wrapper">

        <Navbar/>
        <Sidebar/>
        <Content/>

      </div>
      {/* <!-- Wrapper End--> */}

      <Footer/>

    </div>
  );
}

export default App;
