import React from "react";

function Navbar() {
  return (

    <div className="iq-top-navbar">
        <div className="iq-navbar-custom">
            <nav className="navbar navbar-expand-lg navbar-light p-0">
                <div className="iq-navbar-logo d-flex align-items-center justify-content-between">
                    <i className="ri-menu-line wrapper-menu"></i>
                    <a href="../backend/index.html" className="header-logo">
                            <img src="https://s3.tebi.io/noteplus/images/logo.png" className="img-fluid rounded-normal light-logo" alt="logo"/>
                        
                    </a>
                </div>
                <div className="d-flex align-items-center">
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"  aria-label="Toggle navigation">
                    <i className="ri-menu-3-line"></i>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto navbar-list align-items-center">
                        <li className="nav-item nav-icon search-content">
                            <a href="#" className="search-toggle rounded" id="h1-dropdownSearch" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="ri-search-line"></i>
                            </a>
                            <div className="iq-search-bar iq-sub-dropdown dropdown-menu" aria-labelledby="h1-dropdownSearch">
                                <form action="#" className="searchbox p-2">
                                    <div className="form-group mb-0 position-relative">
                                    <input type="text" className="text search-input font-size-12" placeholder="type here to search..."/>
                                    <a href="#" className="search-link"><i className="las la-search"></i></a> 
                                    </div>
                                </form>
                            </div>
                        </li>                
                        {/* <li className="nav-item nav-icon dropdown mail-content">
                            <a href="#" className="search-toggle dropdown-toggle nav-icon-1" id="h1-dropdownMenuButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="">
                                <svg width="20" className="svg-icon" id="main-n-010" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                </svg>
                            </i>
                            <span className="badge badge-primary count-mail mail rounded-circle">2</span>
                            <span className="bg-primary"></span>
                            </a>
                            <div className="iq-sub-dropdown dropdown-menu" aria-labelledby="h1-dropdownMenuButton2">
                                <div className="card shadow-none m-0">
                                    <div className="card-body p-0 ">
                                        <div className="px-3 pt-0 pb-0">
                                            <a href="#" className="iq-sub-card">
                                                <div className="media align-items-center cust-card py-3 border-bottom">
                                                    <div className="">
                                                        <img className="avatar-50 rounded-small" src="https://s3.tebi.io/noteplus/images/user/01.jpg" alt="01"/>
                                                    </div>
                                                    <div className="media-body ml-3">
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <h5 className="mb-0">Emma Watson</h5>
                                                            <small className="text-dark"><b>12 : 47 pm</b></small>
                                                        </div>
                                                        <small className="mb-0">Lorem ipsum dolor sit amet</small>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="#" className="iq-sub-card">
                                                <div className="media align-items-center cust-card py-3 border-bottom">
                                                    <div className="">
                                                        <img className="avatar-50 rounded-small" src="https://s3.tebi.io/noteplus/images/user/02.jpg" alt="02"/>
                                                    </div>
                                                    <div className="media-body ml-3">
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <h5 className="mb-0">Ashlynn Franci</h5>
                                                            <small className="text-dark"><b>11 : 30 pm</b></small>
                                                        </div>
                                                        <small className="mb-0">Lorem ipsum dolor sit amet</small>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="#" className="iq-sub-card">
                                                <div className="media align-items-center cust-card py-3">
                                                    <div className="">
                                                        <img className="avatar-50 rounded-small" src="https://s3.tebi.io/noteplus/images/user/03.jpg" alt="03"/>
                                                    </div>
                                                    <div className="media-body ml-3">
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <h5 className="mb-0">Kianna Carder</h5>
                                                            <small className="text-dark"><b>11 : 21 pm</b></small>
                                                        </div>
                                                        <small className="mb-0">Lorem ipsum dolor sit amet</small>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <a className="btn btn-block btn-primary position-relative text-center" href="#" role="button">
                                            View All
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="nav-item nav-icon dropdown mail-content"> 
                            <a href="#" className="search-toggle dropdown-toggle nav-icon-1" id="h1-dropdownMenuButton004" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                            <i className="">
                                <svg width="20" className="svg-icon" id="main-n-020" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                                </svg>
                            </i>
                            <span className="badge badge-primary count-mail rounded-circle">2</span>
                            <span className="bg-primary "></span>
                            </a>
                            <div className="iq-sub-dropdown dropdown-menu" aria-labelledby="h1-dropdownMenuButton004">
                                <div className="card shadow-none m-0">
                                    <div className="card-body p-0 ">
                                        <div className="px-3 pt-0 pb-0">
                                            <a href="#" className="iq-sub-card">
                                                <div className="media align-items-center cust-card py-3 border-bottom">
                                                    <div className="">
                                                        <img className="avatar-50 rounded-small" src="https://s3.tebi.io/noteplus/images/user/01.jpg" alt="01"/>
                                                    </div>
                                                    <div className="media-body ml-3">
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <h5 className="mb-0">Emma Watson</h5>
                                                            <small className="text-dark"><b>12 : 47 pm</b></small>
                                                        </div>
                                                        <small className="mb-0">Lorem ipsum dolor sit amet</small>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="#" className="iq-sub-card">
                                                <div className="media align-items-center cust-card py-3 border-bottom">
                                                    <div className="">
                                                        <img className="avatar-50 rounded-small" src="https://s3.tebi.io/noteplus/images/user/02.jpg" alt="02"/>
                                                    </div>
                                                    <div className="media-body ml-3">
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <h5 className="mb-0">Ashlynn Franci</h5>
                                                            <small className="text-dark"><b>11 : 30 pm</b></small>
                                                        </div>
                                                        <small className="mb-0">Lorem ipsum dolor sit amet</small>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="#" className="iq-sub-card">
                                                <div className="media align-items-center cust-card py-3">
                                                    <div className="">
                                                        <img className="avatar-50 rounded-small" src="https://s3.tebi.io/noteplus/images/user/03.jpg" alt="03"/>
                                                    </div>
                                                    <div className="media-body ml-3">
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <h5 className="mb-0">Kianna Carder</h5>
                                                            <small className="text-dark"><b>11 : 21 pm</b></small>
                                                        </div>
                                                        <small className="mb-0">Lorem ipsum dolor sit amet</small>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <a className="btn btn-block btn-primary position-relative text-center" href="#" role="button">
                                            View All
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>   */}
                        <li className="caption-content">
                        <a href="#" className="iq-user-toggle d-flex align-items-center justify-content-between" id="h-dropdownMenuButton001" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <img src="https://s3.tebi.io/noteplus/images/user/1.jpg" className="img-fluid rounded avatar-50" alt="user"/>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right w-100 border-0 py-2" aria-labelledby="h-dropdownMenuButton001">
                                <a className="dropdown-item mb-2" href="../app/user-profile.html">
                                    <i className="lar la-user-circle font-size-20 mr-1"></i>
                                    <span className="mt-2">My Profile</span>
                                </a>
                                <a className="dropdown-item mb-2" href="../app/user-profile-edit.html">
                                    <i className="las la-user-edit font-size-20 mr-1"></i>
                                    <span>Edit Profile</span>
                                </a>
                                <a className="dropdown-item mb-2" href="../app/user-account-setting.html">
                                    <i className="las la-user-cog font-size-20 mr-1"></i>
                                    <span>Account Settings</span>
                                </a>
                                <a className="dropdown-item" href="../app/user-privacy-setting.html">
                                    <i className="las la-user-shield font-size-20 mr-1"></i>
                                    <span>Privacy Settings</span>
                                </a>
                                <hr className="my-1"></hr>
                                <a className="dropdown-item" href="../backend/index.html">
                                    <i className="las la-sign-out-alt font-size-20 mr-1"></i>
                                    <span>Logout</span>
                                </a>
                            </div>
                        </li>
                        </ul>                     
                    </div> 
                </div>
            </nav>
        </div>
    </div>      

  );
}

export default Navbar;
