import React, { useState, useEffect } from "react";
// Hàm escape HTML và thay thế ký tự xuống dòng
function escapeHtml(unsafe) {
    return unsafe
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;")
        .replace(/\n/g, "<br />"); // Thay thế xuống dòng
}

function Content() {
    const [newNote, setNewNote] = useState({});
    const [successMessage, setSuccessMessage] = useState(false);
    const [note, setNote] = useState([]);
    const [selectedNote, setSelectedNote] = useState(null);

    const fetchNotes = () => {
        fetch("https://note.luahty.id.vn/apiNote.php")
            .then((response) => response.json())
            .then((data) => {
                setNote(data);
            })
            .catch((error) => console.error("Lỗi:", error));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewNote({ ...newNote, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        fetch("https://note.luahty.id.vn/apiNote.php", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(newNote),
        })
            .then((response) => response.json())
            .then((data) => {
                // Xử lý phản hồi từ server nếu cần
                console.log(data);
                // Hiển thị thông báo thành công
                setSuccessMessage(true);
                // Reset form
                setNewNote({});
                // Cập nhật danh sách ghi chú
                fetchNotes();
                // Ẩn thông báo sau một khoảng thời gian
                setTimeout(() => {
                    setSuccessMessage(false);
                }, 3000);
            })
            .catch((error) => console.error("Lỗi:", error));
    };


    const handleDelete = (id) => {
        fetch("https://note.luahty.id.vn/apiNote.php", {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ id }),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                // Tải lại danh sách ghi chú sau khi xóa
                fetchNotes();
            })
            .catch((error) => console.error("Lỗi:", error));
    };

    const handleViewNote = (note) => {
        setSelectedNote(note);
        // Open the modal by setting selectedNote state
    };

    const handleCloseModal = () => {
        setSelectedNote(null);
    };

    useEffect(() => {
        fetchNotes();
    }, []);

    return (

        <div className="content-page">

            <div className="container-fluid note-details">
                <div className="desktop-header">
                    <div className="card card-block topnav-left">
                        <div className="card-body write-card">
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="iq-note-callapse-menu">
                                    <a className="iq-note-callapse-btn show-note-button" data-toggle="collapse" href="#collapseMenu" role="button"
                                        data-extra-toggle="toggle" data-extra-class-show=".hide-note-button" data-extra-class-hide=".show-note-button"
                                        aria-expanded="false">
                                        <i className="las la-pencil-alt pr-2"></i>Write Your Note
                                    </a>
                                    <span className="hide-note-button d-none"><i className="las la-folder pr-2"></i>Create your new note</span>
                                </div>
                                <div className="note-right media align-items-top hide-note-button d-none">
                                    <div className="view-btn btn-dropdown body-bg rounded">
                                        <div className="dropdown">
                                            <span className="dropdown-toggle" id="dropdownMenuButton1" data-toggle="dropdown">
                                                <i className="ri-more-2-fill"></i>
                                            </span>
                                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton1">
                                                <a className="dropdown-item" href="#"><i className="lar la-heart mr-3"></i>Add To Favourite</a>
                                                <a className="dropdown-item" href="#"><i className="las la-thumbtack mr-3"></i>Mark As Pin</a>
                                                <a className="dropdown-item" href="#"><i className="las la-trash-alt mr-3"></i>Move to Trash</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {successMessage && (
                    <div className="alert alert-success mt-3" role="alert">Note added successfully!</div>
                )}
                <div className="row">
                    <div className="col-md-12">
                        <div className="collapse" id="collapseMenu">
                            <div className="card card-block card-stretch">
                                <div className="card-body write-card">
                                    <div className="container-fluid collapse-fluid">
                                        <form onSubmit={handleSubmit} action="#">
                                            <div className="row">
                                                <div className="col-md-12 col-lg-12 p-0">
                                                    <div className="card card-block card-stretch event-note">


                                                        <div class="form-group card-header" >
                                                            <input
                                                                type="text" name="title" className="form-control" placeholder="Enter title"
                                                                value={newNote.title || ''} onChange={handleInputChange}
                                                            />
                                                        </div>
                                                        <div className="form-group card-body">
                                                            <textarea
                                                                name="description" rows="4" className="form-control" placeholder="Enter your description"
                                                                value={newNote.description || ''} onChange={handleInputChange}
                                                            ></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 p-0 d-flex justify-content-end">
                                                    <button className="btn btn-outline-primary" data-extra-toggle="toggle" data-extra-class-show=".show-note-button" data-extra-class-hide=".hide-note-button">Close</button>
                                                    <button type="submit" className="btn btn-primary ml-2" data-extra-toggle="toggle" data-extra-class-show=".show-note-button" data-extra-class-hide=".hide-note-button">Save</button>
                                                </div>
                                            </div>
                                        </form>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="card card-block card-stretch">
                            <div className="card-body custom-notes-space">
                                <h3 className="">Your Notes</h3>
                                <div className="iq-tab-content">
                                    {/* <div className="d-flex flex-wrap align-items-top justify-content-between">
                                    <ul className="d-flex nav nav-pills text-center note-tab mb-3" id="note-pills-tab" role="tablist">                                
                                        <li className="nav-item">
                                            <a className="nav-link home active show" data-toggle="pill" data-init="note" href="#note1" role="tab" aria-selected="false">All</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link home" data-toggle="pill" data-init="shared-note" href="#note2" role="tab" aria-selected="true">Shared Notes</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link home" data-toggle="pill" data-init="pin-note" href="#note3" role="tab" aria-selected="false">Pin Notes</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link home" data-toggle="pill" data-init="fav-note" href="#note4" role="tab" aria-selected="false">Favourite Notes</a>
                                        </li>
                                    </ul> 
                                    <div className="media align-items-top iq-grid">
                                        <div className="view-btn rounded body-bg btn-dropdown filter-btn mr-3">
                                            <div className="dropdown">
                                                <span className="dropdown-toggle cursor-pointer" id="dropdownMenuButton003" data-toggle="dropdown">
                                                    <i className="las la-filter font-size-20"></i>
                                                </span>
                                                <div className="dropdown-menu dropdown-menu-right border-none" aria-labelledby="dropdownMenuButton003">                                                
                                                    <div className="dropdown-item mb-3">
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <h6 className="mr-4"><i className="las la-book mr-3"></i>Located In</h6>
                                                            <div className="form-group mb-0">
                                                                <select name="type" className="basic-select form-control dropdown-toggle" data-style="py-0">
                                                                    <option value="1">Project Plans</option>
                                                                    <option value="2">Routine Notes</option>
                                                                    <option value="3">Planning</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="dropdown-item mb-3">
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <h6 className="mr-4"><i className="las la-paste mr-3"></i>Contains</h6>
                                                            <div className="form-group mb-0">
                                                                <select name="type" className="basic-select form-control dropdown-toggle" data-style="py-0">
                                                                    <option value="1">Address</option>
                                                                    <option value="2">Archive Files</option>
                                                                    <option value="3">Code Blocks</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="dropdown-item mb-2">
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <h6 className="mr-4"><i className="las la-calendar mr-3"></i>Created</h6>
                                                            <div className="form-group mb-0">
                                                                <select id="date-select" name="type" className="basic-select form-control dropdown-toggle" data-style="py-0">
                                                                    <option value="today">Today</option>
                                                                    <option value="yest">Yesterday</option>
                                                                    <option value="last-week">Last Week</option>
                                                                    <option value="last-month">Last Month</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                    
                                        <div className="list-grid-toggle cursor-pointer">
                                            <span className="icon active i-grid rounded"><i className="ri-layout-grid-line font-size-20"></i></span>
                                            <span className="icon active i-list rounded"><i className="ri-list-check font-size-20"></i></span>
                                            <span className="label label-list">List</span>
                                        </div>
                                    </div>          
                                </div>              */}
                                    <div className="note-content tab-content">
                                        <div id="note1" className="tab-pane fade active show">
                                            <div className="icon active animate__animated animate__fadeIn i-grid">
                                                <div className="row">


                                                    {Array.isArray(note) && note.map((item) => (
                                                        <div className="col-lg-4 col-md-6">
                                                            <div className="card card-block card-stretch card-height card-bottom-border-info note-detail">
                                                                <div className="card-header d-flex justify-content-between pb-1">
                                                                    <div className="icon iq-icon-box-2 icon-border-info rounded">
                                                                        <svg width="23" height="23" className="svg-icon" id="iq-main-01" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                                                        </svg>
                                                                    </div>
                                                                    <div className="card-header-toolbar d-flex align-items-center">
                                                                        <div className="dropdown">
                                                                            <span className="dropdown-toggle dropdown-bg" id="note-dropdownMenuButton4"
                                                                                data-toggle="dropdown" aria-expanded="false" role="button">
                                                                                <i className="ri-more-fill"></i>
                                                                            </span>
                                                                            <div className="dropdown-menu dropdown-menu-right"
                                                                                aria-labelledby="note-dropdownMenuButton4">
                                                                                <a onClick={() => handleViewNote(item)} href="#" className="dropdown-item new-note1" data-toggle="modal" data-target="#new-note1"><i className="las la-eye mr-3"></i>View</a>
                                                                                {/* <a href="#" className="dropdown-item edit-note1" data-toggle="modal" data-target="#edit-note1"><i className="las la-pen mr-3"></i>Edit</a> */}
                                                                                <a onClick={() => handleDelete(item.id)} className="dropdown-item" data-extra-toggle="delete" data-closest-elem=".card" href="#"><i className="las la-trash-alt mr-3"></i>Delete</a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="card-body rounded">
                                                                    <h4 className="card-title">{item.title}</h4>
                                                                    <p className="mb-3 card-description short" dangerouslySetInnerHTML={{ __html: escapeHtml(item.description) }}></p>
                                                                </div>
                                                                <div className="card-footer">
                                                                    <div className="d-flex align-items-center justify-content-between note-text note-text-info">
                                                                        {/* <a href="#" className=""><i className="las la-calendar mr-2 font-size-20"></i>12 Jan 2021</a> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}



                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Page end  --> */}
            </div>


            {/* <!-- Modal --> */}

            {/* <!-- Modal View --> */}

            {selectedNote && (
                <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="popup text-left">
                                    <div className="media align-items-top justify-content-between">
                                        <h3 className="mb-3">{selectedNote.title}</h3>
                                        <button type="button" className="close" aria-label="Close" onClick={handleCloseModal}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="content create-workform">
                                        <p className="mb-4" dangerouslySetInnerHTML={{ __html: escapeHtml(selectedNote.description) }}></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* <!-- Modal Edit --> */}
            <div className="modal fade" id="edit-note1" tabindex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="popup text-left">
                                <div className="media align-items-top justify-content-between">
                                    <h3 className="mb-3">Weekly Planner</h3>
                                    <div className="btn-cancel p-0" data-dismiss="modal"><i className="las la-times"></i></div>
                                </div>
                                <div className="content edit-notes">
                                    <div className="card card-transparent card-block card-stretch event-note mb-0">
                                        <div className="card-body px-0 bukmark">
                                            <div className="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                                <div className="quill-tool">
                                                </div>
                                            </div>
                                            <div id="quill-toolbar1">
                                                <p>Virtual Digital Marketing Course every week on Monday, Wednesday and Saturday.Virtual Digital Marketing Course every week on Monday</p>
                                            </div>
                                        </div>
                                        <div className="card-footer border-0">
                                            <div className="d-flex flex-wrap align-items-ceter justify-content-end">
                                                <div className="btn btn-primary mr-3" data-dismiss="modal">Cancel</div>
                                                <div className="btn btn-outline-primary" data-dismiss="modal">Save</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Content;
